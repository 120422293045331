/* Default styles for all devices */
* {
	font-family: Trebuchet MS;
  }
  .con8 {
	max-width: 100%;
  }
  .video-container {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%; /* Aspect ratio 16:9 */
  }
  
  .video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  .igh {
	max-width: 80%;
	max-height: 365px;
  }
  .jiujitsu h1 {
	font-size: 120px;
	font-family:fantasy;
	font-weight: 900;
	text-shadow: 4px 4px 7px rgb(218, 0, 0);
	color: #FFFFFF;
	margin-bottom: -10px;
	margin-top:-10px ;
}
 
  /* Styles for mobile devices */
  @media (max-width: 767px) {
	.navbar {
	  text-align: center;
	  display: block;
	  flex-direction:column;
	  align-items: center;
	  max-width: 100%;
	  min-width: 100%;
	}
  
	/* .navLink {
	  margin: 6px;
	  
	} */
  
	.navLink a {
	  margin: 6px 0;
	  padding: 10px;
	  /* width: 100%; */
	  text-align: center;
	  font-size: small;
	}
	#shorty {
		font-size: x-small;
	}
  
	.chiS,
	.mexS {
		width: 100%;
		height: 100%;
		max-height:600px;
		margin-top: -16px
		/* margin-bottom: -25%; */
		
	}
	.sche {
		display: block ;
		text-align: center;
	}
  
	.about img,
	.jiujitsu img {
	  width: 100%;
	  min-height: 360px;
	}
	.sjs {
		background-color: rgb(86, 32, 96);
		/* margin-bottom: -14%; */
		
	  }
	  
  
	.nickB,
	.aril,
	.notAril,
	.blou,
	.jd,
	.horacio,
	.cftlogo {
	  width: 100%;
	  height: auto;
	  background-size: cover;
	}
	.clou{
		
		padding-right: 10%;
		z-index:  2;;
	}
	.cbelal{
		margin-left: -35%;
		z-index:  1;;
		
	}
	.nickB{
		margin-bottom: -270px;
		/* margin-bottom: -35%; */

	}
	
	.horacio {
		margin-bottom: -50%;
		min-height: 20%;
	}
	.jd{
		margin-bottom: -150px;
	}
	.jiujitsuT, .blou {
		margin-bottom: -55%;
	}
	.clou {

		

	}
  
	.foot img {
	  width: 100%;
	  height: 10%;
	}
	.foot {
		margin-bottom: -15px;
	}
  
	.home h1,
	.home2 h1 {
	  font-size: 40px;
	  max-width: 100%;
	}
  
	.jiujitsu h1 {
	  font-size: 60px;
	  /* margin-top: -20px; */
	}
  
	.jiujitsu h3,
	.instructors h3,
	.memberships h3,
	.schedule h3 {
	  font-size: 20px;
	}
  
	.jiujitsuT {
	  width: 100%;
	  height: auto;
	  background-size: 100%;
	  /* margin-bottom: 0; */
	}
  
	.jiujitsu p,
	.about p {
	  font-size: 14px;
	  max-width: 100%;
	}
  
	/* Add more styles for other elements as needed */
  }
  
  .App {
	text-align: center;
  }
  .sche {
	background-color: #000000;
  }
  
  
  .chiS {
	max-width: 100%;
	height: auto;
	background-image: url("./Images/CFTS.jpeg");
	background-repeat: no-repeat;
	background-size:contain;
  }
  
  .mexS {
	max-width: 100%;
	min-width: 100%;
	height: auto;
	background-image: url("./Images/mexS.jpeg");
	background-repeat: no-repeat;
	background-size: contain;
  }
  
  .about img {
	max-width: 100%;
	height: auto;
	z-index: 1;
  }
  
  .jiujitsu img {
	width: 100%;
	height: auto;
  }
  
  .nickB {
	max-width: 100%;
	height: auto;
	background-image: url("./Images/nickB.jpeg");
	background-repeat: no-repeat;
	background-size: 100%;
	margin-top: 80px;
  }
  .jiujitsuT {
	max-width: 100%;
	height: auto;
	background-image: url("./Images/jiujitsuT.jpeg");
	background-repeat: no-repeat;
	background-size: 100%;
	
	
  }
  
  .aril {
	max-width: 100%;
	height: auto;
	background-image: url("./Images/341260897_241927845028189_3175328261831266190_n.jpeg");
	background-repeat: no-repeat;
	background-size: 100%;
  }
  
  .notAril {
	max-width: 100%;
	height: auto;
	background-image: url("./Images/172729431_143343254458624_1812354647126060410_n.jpeg");
	background-repeat: no-repeat;
	background-size: 100%;
  }
  
	.about {
	  background-color: black;
	  background-size: cover;
	  color: white;
	  align-items: center;
	  text-align: center;
	  justify-content: center;
	  /* padding: 20px; */
	  max-width: 100%;
	}
  
	.about h1 {
	  font-size: 30px;
	}
  
	.about p {
	  font-size: 16px;
	  margin-bottom: 20px;
	}
  
	.jiujitsu {
	  background-color: black;
	  color: white;
	  /* padding: 20px; */
	  text-align: center;
	}
  
	/* .jiujitsu h1 {
	  font-size: 40px;
	  margin-top: -10%;
	  margin-bottom: 20px;
	}
   */
	.jiujitsu h3 {
	  font-size: 16px;
	  margin-bottom: 10px;
	}
  
	.instructors {
	  background-color: black;
	  color: white;
	  padding: 20px;
	  text-align: center;
	}
  
	.instructors h1 {
	  font-size: 30px;
	  margin-top: 0;
	  margin-bottom: 20px;
	}
  
	.instructors h3 {
	  font-size: 16px;
	  margin-bottom: 10px;
	}
  
	.memberships {
	  background-color: black;
	  color: white;
	  padding: 20px;
	  text-align: center;
	}
  
	.memberships h1 {
	  font-size: 30px;
	  margin-top: 0;
	  margin-bottom: 20px;
	}
  
	.memberships h3 {
	  font-size: 16px;
	  margin-bottom: 10px;
	}
  
	.schedule {
	  background-color: black;
	  color: white;
	  /* padding: 20px; */
	  text-align: center;
	}
  
	.schedule h1 {
	  font-size: 30px;
	  margin-top: 0;
	  /* margin-bottom: 20px; */
	}
  
	.schedule h3 {
	  font-size: 16px;
	  /* margin-bottom: 10px; */
	}
  
	.footer {
	  background-color: black;
	  color: white;
	  padding: 20px;
	  text-align: center;
	}
  
	.footer h3 {
	  font-size: 20px;
	  margin-top: 0;
	  margin-bottom: 10px;
	}
  
	.footer p {
	  font-size: 14px;
	  /* margin-bottom: 10px; */
	}
  
  
  /* Add more styles for other elements as needed */



.App {
  text-align: center;
}

.chiS {
  width: 1200px;
  height: 1000px;
  background-image: url("./Images/CFTS.jpeg");
  background-repeat: no-repeat;
  background-size: contain;
}
.mexS {

	width: 1200px;
	height: 1000px;
	background-image: url("./Images/mexS.jpeg");
	background-repeat: no-repeat;
	background-size: contain;
	align-content: center;
	text-align: center;
	justify-content: center;
	justify-items: center;
	/* width: fit-content; */
	

}
.about img{
	width: 460px;
  	height: 360px;
	z-index: 1;
}
.jiujitsu img {
	width: 860px;
  	height: 560px;
}
.nickB {
	width: 860px;
  	height: 560px;
	background-image: url("./Images/nickB.jpeg");
  	background-repeat: no-repeat;
 	background-size: 100%;
	
	
}
.jiujitsuT {
	width: 860px;
	max-width: 100%;
  	height: 560px;
	background-image: url("./Images/jiujitsuT.jpeg");
  	background-repeat: no-repeat;
 	background-size: 100%;
	
}
.aril {
	width: 860px;
	height: 500px;
	background-image: url("./Images/341260897_241927845028189_3175328261831266190_n.jpeg");
	background-repeat: no-repeat;
	background-size: 100%;
}
.notAril {
	width: 600px;
	height: 500px;
	background-image: url("./Images/172729431_143343254458624_1812354647126060410_n.jpeg");
	background-repeat: no-repeat;
	background-size: 100%;
}

.about {
	background-color: black;
	background-size: cover;
	color: white;
	align-items: center;
	text-align: center;
	/* margin-top: -25px; */
	justify-content: center;
	width: 100%;
}

.blou {
	max-width: 100%;
	width: 860px;
  	height: 460px;
	  background-image: url("./Images/aboutheader.jpeg");
	  background-repeat: no-repeat;
	  background-size: 100%;
	  padding-bottom: 0px;
	  /* margin-bottom: 0px; */
}
.clou {
	width: 240px;
  	height: 360px;
	min-width: 10;
	min-height: 360px;
	background-image: url("./Images/clou.jpeg");
	background-repeat: no-repeat;
	background-size:cover ;
}
.cbelal {
	width: 550px;
  	height: 360px;
	background-image: url("./Images/cBelal.jpeg");
	background-repeat: no-repeat;
	background-size:cover;
}
.jd {
	width: 460px;
  	height: 355px;
	background-image: url("./Images/jd.jpeg");
	
	background-repeat: no-repeat;
	background-size: 100%;

}
.horacio {
	width: 460px;
  	min-height: 360px;
	background-image: url("./Images/horacio.jpeg");
	background-repeat: no-repeat;
	background-size:100%;
}
.hajd {
	display: flex;
	margin: auto;
	justify-content: center;
	max-width: 100%;
	max-height: 50%;

	

}
#fbfooter {
	margin-top: 25px;
	width: 860px;
  	height: 460px;
	margin-top: -100px;
	
}

.about p {
	line-height: 1.5; /* increase the line height for better readability */
	margin-bottom: 1em; /* add some space between paragraphs */
	max-width: 800px;
	display: inline-block;
	text-align: center;
}


.cftlogo {
  width: 360px;
  height: 260px;
  background-image: url("./Images/oldCft.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
  margin-bottom: 0px;
}

.memberships2 form {
	background-color: rgba(200, 193, 193, 0.513);

}
.memberships1 form {
	background-color: rgba(200, 193, 193, 0.513);
}

.memberships1 {
	background-color: rgba(55, 53, 53, 0.65);
	/* background-image: url("./Images/chicago.jpeg"); */
	color: white;
	text-align: center;
	margin-bottom: -20px;
	/* background-image: url("./Images/chicago.jpeg"); */

}
.memberships1 h2 {
	font-weight: 900;
	text-shadow: 4px 4px 7px rgba(218, 0, 0, 0.5);
}
.memberships2 {
	/* background-image: url("./Images/mexico.jpeg"); */
	background-image: url("./Images/mexico.jpeg");
	color: white;
	text-align: center;
	margin-bottom: -20px;
}
.memberships2 h2 {
	color: rgb(255, 255, 255);
	background-color: rgba(0, 0, 0, 0.754);
	font-weight: 900;
	text-shadow: 4px 4px 7px rgba(218, 0, 0, 0.5);
}
.foot {
	background-color: black;
	background-size: cover;
	color: white;
	margin-bottom: -25px;
	background-image: url(./Images/tester.jpeg);
	background-size: contain;
	border-top: 5px solid black;
	/* padding: 20px;  */
	min-width: 100%;
  }
  
  .foot img {
	width: 1px;
	height: 180px;
  }
  

.jiujitsu {
	margin-top: -25px;
	background-color: black;
	background-size: cover;
	color:white;
	text-align: center;
	margin-bottom: -16px;
	margin-top: -80px;
}
.jiujitsu p {
	line-height: 2.0; /* increase the line height for better readability */
	margin-bottom: 1em; /* add some space between paragraphs */
	max-width: 800px;
	display: inline-block;
	text-align: center;
	font-family:sans-serif;
	font-size: 18px;
	
}

.about p {
	line-height: 2.0; /* increase the line height for better readability */
	margin-bottom: 1em; /* add some space between paragraphs */
	max-width: 830px;
	display: inline-block;
	text-align: center;
	font-family:sans-serif;
	font-size: 18px;
	
}

.home h1 {
	font-size: 80px;
	font-family:sans-serif;
	font-weight: 900;
	text-shadow: 4px 4px 7px rgb(210, 0, 0);
	color: #000000;
	margin-top: -10px;
	margin-bottom: -5px;
}
.home2 h1 {
	font-size: 80px;
	font-family:sans-serif;
	font-weight: 900;
	text-shadow: 4px 4px 7px rgb(183, 0, 0);
	color: #ffffff;
	margin-top: -10px;
	margin-bottom: -5px;
}
.home2 h2 {
	color:rgb(3, 82, 21);
	text-shadow: 5px 5px 10px rgb(255, 255, 255);

}
.jiujitsu h3 {
	font-size: 55px;
	font-family:fantasy;
	/* font-weight: 900; */
	text-shadow: 4px 4px 7px rgb(218, 0, 0);
	color: #FFFFFF;
	margin-bottom: -15px;
	margin-top: -15px;

}
.rightList {
	text-align: right;


}
.mbg {
	
	background-image: url("./Images/chicago.jpeg");
	background-size: cover;
	margin-top: -20px;
	
}
ul {
	background-color: rgba(55, 53, 53, 0.65);
    display: inline-block;
    *display: inline;
    zoom: 1;
	color:#eee

}
.leftList {
	text-align: left;


}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {
  color: #61dafb;
} */
.input {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
	padding-top: 1.5rem;
	&+.input {
		margin-top: 1.5rem;
	}
}
.acct {
	background-color: rgb(9, 8, 8);
	/* background-color: ; */
	/* margin-left: -10%;
	margin-right: -10%; */
	color:white;
	text-align: right;
	
	margin-top: -10px;
	width: 100%;
	

}
.navbar {
	max-width: 100%;
	margin-top:0%;
}
.navLink {
	background-color: rgb(9, 8, 8);
  	display: flex;
  	flex-direction: row;
  	justify-content: space-between;
	  text-align: center;
	  font-weight: 800;
	  /* text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5); */
}

.input-label {
	color: #000000;
	position: absolute;
	top: 1rem;
	/* transition: .25s ease; */
}
.home {
	/* background-color: black; */
	color:rgb(0, 0, 0);
	text-align: center;
	font-weight: 900;
	text-shadow: 3px 3px 5px rgb(255, 255, 255);
  }
.fish {
	background-color: rgba(0, 0, 0, 0.268);
	background-size:auto;
	font-weight: 900;
	color: white;
	text-shadow: 2px 2px 4px rgb(0, 0, 0);
	/* margin-left: 250px;
	margin-right: 250px; */
}
button {
	border: 3px solid black ;
	background: #FF4742;
	border: 3px solid #000000;
	border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
	box-sizing: border-box;
	color: #FFFFFF;
	cursor: pointer;
	display: inline-block;
	font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
	font-size: 16px;
	font-weight: 800;
	line-height: 16px;
	min-height: 40px;
	outline: 0;
	padding: 12px 14px;
	text-align: center;
	text-rendering: geometricprecision;
	text-transform: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #FF4742;
}

.button-24:active {
  opacity: .5;
}

.home2 {
	/* background-color: black; */
	
	text-align: center;
	background: url(./Images/guadalajara.jpeg) no-repeat center center fixed; 
	margin-top: -21px;
	background-size: cover;
	background-color: rgba(0, 0, 0, 0.5);
	
	

  }
  .jiujitsu h1{
	max-width: 100%;
  }
  html { 
	background: url("./Images/oldbg.jpeg") no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  }
  

.input-field {
	color: #000000;
	border: 0;
	z-index: 1;
	background-color: transparent;
	border-bottom: 2px solid rgb(0, 0, 0); 
	font: inherit;
	font-size: 1.125rem;
	padding: .25rem 0;
	&:focus, &:valid {
		outline: 0;
		border-bottom-color: black;
		&+.input-label {
			color: rgb(0, 0, 0);
			transform: translateY(-1.5rem);
		}
	}


}
